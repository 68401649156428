<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
          <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
            <content-block
              :id="`section__${index}`"
              :title="item.title"
              :tools="item.tools"
              :date="item.date"
              :is-flex="item.isFlex"
              :content="item.content"
              :images="item.images" />
          </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Home",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          tools: 'Beard preparation, sculpture, ambrotype, fanzine',
          date: '2020',
          title: "FORTIS FEMINAE",
          content: `"Zsuzsi Simon's Fortis Feminae is a group of works dealing with the expectations and stereotypes associated with the female body image, based around the figure of Wilgefortis, a Catholic female saint known from 11th century legends, who appears in the depictions with a beard. According to legend, her father wanted to have her married against her will and against her vow of chastity, so she prayed to God to strip her of her beauty. Waking up one day with facial hair, Wilgefortis was angrily crucified by her father. Wilgefortis became the patron saint of women who had suffered abuse and who wanted to escape the cruelty of men. Zsuzsanna Simon uses the story to focus on the heroic reaction to the oppression of women and the symbolism of women's body hair becoming 'masculine': in the exhibition, a self-portrait of the artist with a beard, a crucified sculpture of Wilgefortis and a fanzine link the medieval story to its contemporary relevance.
                  \nSimon's work seeks to break down the taboo of discourse and representation around female hair by revisiting the extreme story of Wilgefortis: the fanzine for the exhibition addresses the representational, cultural, physiological and psychological aspects of hirsutism, the phenomenon of increased female body hair growth." \n\nFlóra Barkóczi`,
          images: [
            { src: require("../assets/img/fortis-femiane/varoszoba_1.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_ambrotipia.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_2.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_onarckep.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_onarckep_2.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_3.png"), claimed: false },
            { video: 'https://www.youtube.com/embed/fgq_2MrmcnY', isVideo: true},
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_1.jpg"), claimed: false },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_2.jpg"), claimed: false },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_3.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_4.jpg"), claimed: false },
                  //szettbe


          ]
        },
      ],
      blocksHU: [
        {
          tools: 'Szakáll preparátum, szobor, ambrotípia, fanzine',
          date: '2020',
          title: "FORTIS FEMINAE",
          content: `“A női testképhez kapcsolt elvárásokkal, sztereotípiákkal foglalkozik Simon Zsuzsi Fortis Feminae című műcsoportja, mely a 11. századi legendákból ismert katolikus női szent, Wilgefortis alakja köré épül, aki az ábrázolásokon szakállal jelenik meg. Wilgefortist a legenda szerint akarata és szüzességi fogadalma ellenére szerette volna férjhez adni apja, a lány ezért Istenhez fohászkodott, hogy vegye el szépségét. Az egyik nap arcszőrzettel ébredő Wilgefortist apja dühében keresztre feszíttette. Vértanúhalálára utal neve is, a virgo fortis, azaz ‘bátor szűz’ elnevezés nyomán. Wilgefortis az abúzust elszenvedett, valamint férfiak kegyetlenségeitől szabadulni akaró nők védőszentjévé vált. Simon Zsuzsanna a történetből a nők elnyomására adott heroikus reakciót és a „férfiassá” váló női testszőrzet szimbolikáját helyezi középpontba: a kiállított műegyüttesben az alkotó szakállas fotó-önportréja, a keresztre feszített Wilgefortist ábrázoló kegytárgy-jellegű szobor és egy fanzin kapcsolja össze a középkori történetet azok jelenkori aktualitásával.
                    \nSimon munkája Wilgefortis szélsőséges történetének felelevenítésén keresztül a női szőrzet körüli diskurzusok és ábrázolások tabu jellegét kívánja lerombolni: a kiállításra készült fanzin a hirsutizmus, a fokozott női testszőr-növekedés jelenségének ábrázolás- és kultúrtörténeti, élettani, valamint pszichológiai vonatkozásait egyaránt érinti.” \n\nBarkóczi Flóra`,
          images: [
            { src: require("../assets/img/fortis-femiane/varoszoba_1.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_ambrotipia.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_2.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_onarckep.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_onarckep_2.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_3.png"), claimed: false },
            { video: 'https://www.youtube.com/embed/fgq_2MrmcnY', isVideo: true},
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_1.jpg"), claimed: false },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_2.jpg"), claimed: false },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_3.jpg"), claimed: true },
            { src: require("../assets/img/fortis-femiane/varoszoba_enterior_4.jpg"), claimed: false },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
  .selector p{
    margin-right: 20px;
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
