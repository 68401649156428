<template>
  <div>
    <div style="display: compact;  ">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Birth",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2014-2016',
          title: "I WILL NOT GIVE BIRTH UNTIL THE CHANGE OF GOVERNMENT!",
          content: `I invited girls/women to join a fictional movement I created. In a series of photos using the language of activism and art, I wrote "I won't give birth until the government changes!" in lipstick on girls' bellies (including mine). With this gesture, I wanted to make a stand against the government's family policy and give space to other women to express similar sentiments and make our voices heard together. The project is an artistic demonstration, a stand that attempts to draw attention to unprincipled and offensive political practices.
                    `,
          images: [
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_1.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_2.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_3.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_4.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_5.jpg"), claimed: true },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_6.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_7.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_8.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_9.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_10.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_11.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_12.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_13.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_14.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_15.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_16.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_17.jpg"), claimed: false,
            text: 'As many people thought it was an extreme political action, it caused a great outcry and the images went viral on the internet, on both right and left wing platforms but provocation is also an important part of the work. I received threatening messages, there were a lot of memes and posts about it, comments by Franciscan Brother Csaba Böjte and the right-wing journalist Zsolt Bayer, who called us a bunch of stupid chicks.'},
            { src: require("../assets/img/birth/meme_01.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_02.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_03.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_04.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_05.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_06.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_07.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_08.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_09.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_10.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_11.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_12.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_13.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_14.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_15.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_16.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_17.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_18.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_19.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_20.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_21.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_22.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_23.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_24.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_25.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_26.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_27.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_28.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_29.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_30.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_31.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_32.jpg"), claimed: false },
          ]
        },
      ],
      blocksHU: [
        {
          date: '2014-2016',
          title: "KORMÁNYVÁLTÁSIG NEM SZÜLÖK!",
          content: `Lányokat/nőket kértem fel arra, hogy csatlakozzanak az általam kreált fiktív mozgalomhoz. Az aktivizmus és a művészet nyelvét használó fotósorozatomban lányok hasára (beleértve az enyémet is) írtuk fel  rúzzsal, hogy “Kormányváltásig nem szülök!”. Ezzel a gesztussal szerettem volna valamilyen módon kiállni a kormány családpolitikája ellen és teret adni más nőknek is, hogy kifejezzék hasonló érzéseiket és együttes erővel hallassuk a hangunkat. A projekt művészi demonstráció, kiállás, amely megpróbálja ráirányítani a figyelmet az elvtelen és sértő politikai gyakorlatra.`,
          images: [
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_1.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_2.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_3.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_4.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_5.jpg"), claimed: true },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_6.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_7.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_8.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_9.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_10.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_11.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_12.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_13.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_14.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_15.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_16.jpg"), claimed: false },
            { src: require("../assets/img/birth/kormanyvaltasig_nem_szulok_17.jpg"), claimed: false,
            text: 'Mivel sokan azt hitték, hogy szélsőséges politikai akció, ezért nagy felháborodást keltett és a képek vírusként terjedtek el az interneten, mind a jobb és mind a baloldal felületein de a provokáció is fontos része a műnek. Kaptam fenyegető üzeneteket, rengeteg mém és poszt született, nyilatkozott róla, Böjte Csaba ferences testvér és a jobboldali újságíró Bayer Zsolt is, aki hülye libáknak nevezett minket.'},
            { src: require("../assets/img/birth/meme_01.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_02.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_03.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_04.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_05.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_06.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_07.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_08.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_09.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_10.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_11.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_12.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_13.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_14.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_15.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_16.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_17.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_18.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_19.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_20.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_21.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_22.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_23.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_24.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_25.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_26.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_27.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_28.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_29.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_30.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_31.jpg"), claimed: false },
            { src: require("../assets/img/birth/meme_32.jpg"), claimed: false },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
