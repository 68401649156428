import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Birth from "../views/Birth";
import Bravo from "../views/Bravo";
import Parents from "../views/Parents";
import Shy from "../views/Shy";
import Source from "../views/Source";
import Trembling from "../views/Trembling";
import Ugly from "../views/Ugly";
import Veronika from "../views/Veronika";
import Bio from "../views/Bio";
import Single from "../views/Single";
import Alexandru from "@/views/Alexandru";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Alexandru,
  },
  {
    path: "/feminae",
    name: "Feminae",
    component: Home,
  },
  {
    path: "/birth",
    name: "Birth",
    component: Birth,
  },
  {
    path: "/bravo",
    name: "Bravo",
    component: Bravo,
  },
  {
    path: "/parents",
    name: "Parents",
    component: Parents,
  },
  {
    path: "/bio",
    name: "Bio",
    component: Bio,
  },
  {
    path: "/Shy",
    name: "Shy",
    component: Shy,
  },
  {
    path: "/source",
    name: "Source",
    component: Source,
  },
  {
    path: "/trembling",
    name: "Trembling",
    component: Trembling,
  },
  {
    path: "/ugly",
    name: "Ugly",
    component: Ugly,
  },
  {
    path: "/veronika",
    name: "Veronika",
    component: Veronika,
  },
  {
    path: "/single",
    name: "Single",
    component: Single,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
