<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Home",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2021',
          title: "ALEXANDRU",
          content: `"Although Zsuzsi Simon's new work seems to be a turn and a change of subject from her earlier women-centred projects, which focused mainly on feminism, female body image and female thinking, it is an organic continuation of them. Since if woman, the female beauty, the appearance and spectacle of woman is a construction, inherently not without interest but the oppressive creation of man, then conversely, man, masculine principles, the spectacle of man can also be called into question and made the subject of research. For according to the logic of binary oppositions, the man, by creating/colonizing the woman for himself, also constructs himself in relation to her, or more precisely, over her. In her project Alexandru, Zsuzsi Simon is interested in man, masculinity, the construction of masculinity, the body of stereotypes, not through the male gaze, which is suggested by men, but through the gaze of a woman, specifically Zsuzsi Simon.
In a blatant example of her subversive process, Simon inverts - or rather further inverts - the remake of Jeff Wall's famous Picture for Women (1979), in such a way that, although Wall had already deconstructed the traditional male-female, creator-model roles, Simon's picture displays the man floating in the aura/image of his self-conscious masculinity as the muse or model of a female creator.
Thus, Alexandru, which is the first phase of a larger male research, could be subtitled as Image for Man/Men/Muse, through the analytical, critical, yet admiring gaze of a woman. However, the complex work of Simon and her eye does not only scan the concrete body, the raw meat, but also creates metaphors of masculinity, strength, manliness, and thus in a sense violence, but also vulnerability. The project shows both the image of a man formed by himself - what he is proud of, what he wants, and what he hides, sublimates and does not show - as well as the way how a woman sees it."\n\nGyörgy Cséka
`,
          images: [
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_01.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_02.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_03.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_04.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_05.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_06.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_07.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_08.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_09.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_10.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_11.jpg"), claimed: false },
          ]
        },
      ],
      blocksHU: [
        {
          date: '2021',
          title: "ALEXANDRU",
          content: `"Simon Zsuzsi új munkája bár látszólag fordulat és témaváltás korábbi nőközpontú, főként a feminizmusra, a női testképre, női gondolkodásra fókuszáló projektjeihez képest, azoknak mégis szerves folytatása. Hiszen, ha a nő, a női szépség, a nő megjelenése, látványossága konstrukció, mégpedig, eredetét tekintve a férfi csöppet sem öncélú vagy érdek nélküli, hanem elnyomó alkotása, úgy megfordítva, a férfi, a férfias princípiumok, a férfi látványa is kérdésessé, kutatás tárgyává tehető. Hiszen a bináris oppozíciók logikájából következően a férfi azzal, hogy megalkotja/gyarmatosítja önmaga számára a nőt, önmagát is hozzá-, pontosabban fölé-konstruálja. Simon Zsuzsit Alexandru című projektjében egy konkrét férfin keresztül a férfi, a férfiasság, a férfiszépség felépítése, a sztereotípiák teste érdekli, mégpedig nem a férfiak által sugallt, objektívnek és természetesnek beállított férfitekinteten, hanem egy nő, konkrétan Simon Zsuzsi tekintetén keresztül.
Az alkotó felforgató eljárásának eklatáns példájaként megfordítja, pontosabban tovább fordítja, a remake remake-jét alkotja meg Jeff Wall nevezetes, Picture for Women (1979) képének, oly módon, hogy bár korábban már Wall is dekonstruálta a hagyományos férfi-nő, alkotó-modell szerepeket, Simon képén egy női alkotó múzsájaként, modelljeként inszcenírozza az öntudatos férfiasságának aurájában/képében lebegő férfit.
Ekképp az Alexandru – amely egy nagyobb férfikutatás kezdeti fázisa – alcíme lehetne akár a Kép a férfinak/a férfiaknak/a múzsának is, egy nő elemző, kritikus, ám emellett csodáló tekintetén keresztül. Simon komplex munkája, szeme azonban nemcsak a konkrét testet, a húst tapogatja le, hanem megalkotja a férfiasság, az erő, a maszkulinitás, így bizonyos értelemben az erőszak, de ezzel együtt a sérülékenység metaforáit is. Egyszerre mutatja azt a képet, amit a férfi magáról alkot, sugároz - mi az, amire büszke, mi akaratának célja, és mi az, amit elrejt, szublimál, amit nem mutat meg magából -, és azt, ahogyan ezt egy nő látja."\n\nCséka György`,
          images: [
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_01.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_02.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_03.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_04.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_05.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_06.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_07.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_08.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_09.jpg"), claimed: false },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_10.jpg"), claimed: true },
            { src: require("../assets/img/alexandru/Zsuzsi_Simon_Alexandru_11.jpg"), claimed: false },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
