<template>
  <div class="home" :id="id">
    <p class="font__header font__family-500">{{ title }}</p>
    <p class="font__header-tools font__family-500">{{ tools }}</p>
    <p class="font__header-date font__family-500">{{ date }}</p>

    <p class="font__content font__family-300">{{ content }}</p>

    <div v-if="!!isFlex">
      <div class="d-flex">
        <div v-for="(item, index) in images">
          <div v-if="!!item.isVideo">
            <iframe width="630" height="355" :src="item.video" title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>

          <div v-else>
            <img :class="{img__claimed: item.claimed, img__invested: !item.claimed}" :src="item.src"
                 @click="openGallery(images, index)" />
            <br />
          </div>


        </div>
      </div>
    </div>

    <div v-else>
      <div v-for="(item, index) in images">
        <div v-if="!!item.isVideo">
          <iframe width="630" height="355" :src="item.video" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>

        <div v-else>
          <img :class="{img__claimed: item.claimed, img__invested: !item.claimed}" :src="item.src"
               @click="openGallery(images, index)" />
          <br />
          <p v-if="!!item.text" class="img__text font__family-300">{{ item.text }}</p>
          <br />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "ContentBlock",
  props: {
    id: String,
    title: String,
    content: String,
    images: Array,
    isFlex: Boolean,
    tools: String,
    date: String
  },
  methods: {
    openGallery(gallery, index) {
      this.$store.commit("setGallery", gallery);
      this.$store.commit("setGalleryIndex", index);
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
.img__text {
  width: 630px;
  margin-left: 15px;
  font-size: 10pt;
}

iframe {
  margin-left: 15px;
  margin-bottom: 40px;
}

.img__claimed {
  width: 380px;
}

.img__invested {
  width: 630px;
}


img {
  cursor: pointer;
  margin-left: 15px;
  /*width: 600px;*/
  margin-bottom: 40px;
  display: inline-block;
  transition: .2s ease;
}

.font__content {
  font-size: 10pt;
  color: #404041;
  width: 630px;
  margin-left: 15px;
  margin-bottom: 60px;
  white-space: break-spaces;
}

.font__header {
  margin-left: 15px;
  position: relative;
  font-size: 12pt;
  color: #404041;
  margin-bottom: 0px;
  z-index: 0;
}

.font__header-tools {
  margin-left: 15px;
  position: relative;
  font-size: 10pt;
  color: #404041;
  margin-bottom: 3px;
}

.font__header-date {
  margin-left: 15px;
  position: relative;
  font-size: 10pt;
  color: #404041;
  margin-bottom: 25px;
}


.font__header:before {
  position: absolute;
  left: -18px;
  top: -8px;
  content: "• ";
  color: #BF99E4;
  font-size: 18pt;
}

.home {
  width: 1100px;
  /*background-color: aqua;*/
  margin-bottom: 60px;
}

@media only screen and (max-width: 768px) {
  .font__content{
    width: 80%;
    margin-left: 30px;
  }
  .home{
    width: 100vw;
    /*padding-left: 10px;*/
  }
  img{
    width: 100% !important;
    margin-left: 0;
  }

  iframe{
    width: 100%;
    margin-left: 0;
  }

  .font__header{
    margin-left: 30px;
    width: 80%;
  }

  .img__text{
    width: 80%;
  }

  .font__header-date, .font__header-tools{
    margin-left: 30px;
  }
}
</style>