<template>
  <div class="menu" id="nav">
    <div style="position: relative; height: inherit">
      <div class="menu__items">
        <transition name="fade" mode="out-in">
          <div v-if="language === 'en'" key="langen" style="display: grid">
            <template v-for="(item, index) in entries">
              <router-link :to="item.route" @click=""> {{item.nameEN}}</router-link>
            </template>
            <router-link class="menu__bio" to="/bio">Bio</router-link>
          </div>

          <div v-else key="langhu" style="display: grid">
            <template v-for="(item, index) in entries">
              <router-link :to="item.route"> {{item.nameHU}}</router-link>
            </template>
            <router-link class="menu__bio" to="/bio">Bio</router-link>
<!--            <p  @click="scrollTo('/bio')">Bio</p>-->
          </div>
        </transition>




      </div>


      <div class="menu__language">
        <p :class="{ menu__active: language === 'en' }" @click="changeLanguage('en')">ENG</p>
        <p class="slash">/</p>
        <p :class="{ menu__active: language === 'hu' }" @click="changeLanguage('hu')">HU</p>
      </div>

      <p class="menu__copyright">©{{ new Date().getFullYear() }} Copyright ZSUZSI SIMON</p>
    </div>

  </div>

</template>

<script>
export default {
  name: "SideMenu",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  watch:{
    $route (to, from){
      window.scrollTo(0, 0);
    }
  },
  data: function() {
    return {
      active: 0,
      entries: [
        { nameEN: 'Alexandru', nameHU: 'Alexandru', route: '/'},
        { nameEN: "Fortis Feminae", nameHU: "Fortis Feminae", route: '/feminae' },
        { nameEN: "Zsuzsanna at the source", nameHU: "Zsuzsanna a Forrásnál", route: '/source' },
        { nameEN: "Ugly or Beauty?", nameHU: "Ugly or Beauty?", route: '/ugly' },

        { nameEN: "My beautiful parents", nameHU: "Gyönyörű szüleim", route: '/parents' },
        { nameEN: "BRAVO", nameHU: "BRAVO", route: '/bravo' },
        { nameEN: "I will not give birth...", nameHU: "Kormányváltásig nem szülök!", route: '/birth' },
        { nameEN: "Shy Girls", nameHU: "Szégyenlős lányok", route: '/shy' },
        { nameEN: "Veronika's wipe", nameHU: "Veronika kendője", route: 'veronika' },
        { nameEN: "Trembling", nameHU: "Reszketés", route: '/trembling' },
        { nameEN: "Single pictures", nameHU: "Képek", route: '/single' },
      ],
      isScrolling: false
    };
  },
  methods: {
    setActive(index) {
      this.active = index;
    },
    changeLanguage(value) {
      this.$store.commit("setLanguage", value);
    },
    changeLinkState() {
      const links = document.querySelectorAll(".menu__items p");
      const sections = document.querySelectorAll(".home");
      let index = sections.length;
      while (--index && window.scrollY + 190 < sections[index].offsetTop) {
      }
      links.forEach((link) => link.classList.remove("menu__active"));
      links[index].classList.add("menu__active");

    },
    scrollTo(route) {

      window.scrollTo({
        top: 180,
        behavior: "smooth"
      });
    }
  },
  mounted() {
    // this.changeLinkState();
    // window.addEventListener("scroll", this.changeLinkState);
  }
};
</script>

<style scoped>
.menu__copyright {
  font-family: museo-500, serif;
  font-size: 7pt !important;
  position: absolute;
  color: #404041;
  top: 83.5vh;
  pointer-events: none;
  z-index: 10;
  margin-left: -15px;
}

.menu__language p:hover:before {

  margin-top: 9.5px;
}


.menu__language .menu__active:before {
  margin-top: 9.5px;
}

.menu__language .slash {
  pointer-events: none;
}

.menu__language p {
  display: inline;
}

.menu__language {
  margin-top: 75px;
}

.d-flex p {
  font-size: 8pt;
}

.menu__bio {
  margin-top: 75px;
}

p:hover:before {
  content: "";
  width: 7.4px;
  height: 7.4px;
  border-radius: 100%;

  position: absolute;
  left: -18px;
  margin-top: 6px;
  background: #BF99E4;
}


.menu {
  position: fixed;
  /*width: 320px;*/
  /*background-color: burlywood;*/
  height: 100vh;
  top: 115px;
  padding-left: 17px;
}

p {
  font-size: 10pt;
  font-family: museo-500, serif;
  cursor: pointer;
  color: #C1C2C4;
  width: 200px;
}

p:hover {
  font-family: museo-500, serif;
  color: #404041;
  transition: all 0.2s ease;
}

.menu__active {
  color: #404041;
  font-family: museo-500, serif;
  pointer-events: none;
}

.menu__active:before {
  /*position: absolute;*/
  /*left: -14px;*/
  /*top: -10px;*/
  /*font-size: 18pt;*/
  /*content:"• ";*/
  /*color: #BF99E4;*/

  content: "";
  width: 7.4px;
  height: 7.4px;
  border-radius: 100%;

  position: absolute;
  left: -18px;
  margin-top: 6px;
  background: #BF99E4;

}

.menu__active:after {
  content: "";
}



a {
  font-size: 10pt;
  font-family: museo-500, serif;
  cursor: pointer;
  color: #C1C2C4;
  width: 200px;
  text-decoration: none;
  margin-bottom: 12px;
  /*white-space: pre;*/
}

a:hover {
  font-family: museo-500, serif;
  color: #404041;
  transition: all 0.2s ease;
}

a:hover:before {
  content: "";
  width: 7.4px;
  height: 7.4px;
  border-radius: 100%;

  position: absolute;
  left: -18px;
  margin-top: 6px;
  background: #BF99E4;
}

/*a:link {*/
/*  color: var(--color-light-grey);*/
/*}*/

/*!* visited link *!*/
/*a:visited {*/
/*  color: var(--color-dark-grey);*/
/*}*/


/* selected link */

#nav a.router-link-exact-active {
  color: #404041;
  font-family: museo-500, serif;
  pointer-events: none;
}

#nav a.router-link-exact-active:before{
  content: "";
  width: 7.4px;
  height: 7.4px;
  border-radius: 100%;

  position: absolute;
  left: -18px;
  margin-top: 6px;
  background: #BF99E4;
}

@media only screen and (max-width: 768px) {
  .menu{
    top: 110px;
    left: 10px;
  }

  .menu__bio{
    margin-top: 40px;
  }

  .menu__copyright{
    display: none;
  }

  .menu__language{
    margin-top: 40px;
  }
}
</style>