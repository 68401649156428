<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Single",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      blocksEN: [
        {
          title: "SINGLE PICTURES",
          images: [
            { src: require("../assets/img/single/single_pictures_1.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_2.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_3.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_4.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_5.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_6.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_6_1.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_7.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_8.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_9.jpg"), claimed: true },

          ]
        },
      ],
      blocksHU: [
        {
          title: "KÉPEK",
          images: [
            { src: require("../assets/img/single/single_pictures_1.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_2.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_3.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_4.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_5.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_6.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_6_1.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_7.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_8.jpg"), claimed: false },
            { src: require("../assets/img/single/single_pictures_9.jpg"), claimed: true },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
