<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Ugly",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          tools: 'Photo poster, armpit hair preparation',
          date: '2018',
          title: "UGLY OR BEAUTY?",
          content: "Some people tune up their cars, others put on make-up or pimp their gardens, while some beautify their body hair. The hair that usually makes people disapprove. \n" +
            "But if it's adorned, do we still see it as ugly or beautiful? Does it change our body image if we take care of it and tidy it up? \n" +
            "Would you wear an embellished pubic hair wig when you go on a date and shiny armpit hair when you go to the beach? Glittering, ornate, gorgeous wonder! The dream of every woman and man! Guaranteed to make you proud to wear it!\n",
          images: [
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_enterior.jpg"), claimed: false },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_holdfeny_kicsi.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_lakota.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_techno_kicsi.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_tini_kicsi.jpg"), claimed: true },
            //szett
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_wedding_1.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_wedding_2.jpg"), claimed: true },
          ]
        },
      ],
      blocksHU: [
        {
          tools: 'Fotó plakát, hónaljszőr preparátum',
          date: '2018',
          title: "UGLY OR BEAUTY?",
          content: "Van, aki a verdáját tuningolja, más kisminkeli magát vagy a kertjét csinosítgatja, valaki pedig a testszőrzetét szépítgeti. A szőrt, ami általában elutasítást vált ki az emberekből. \n" +
            "De ha ékesítve van, akkor is csúnyának látjuk vagy inkább szépnek? Megváltozik a testképünk, ha foglalkozunk vele és kicicomázzuk? \n" +
            "Te viselnél díszített fanszőr parókát, ha randira és ragyogó hónaljékszőrt, ha strandra mész? Csillogó, díszekkel teli, pompás csodaság! Minden nő és férfi álma! Garantáltan büszkén fogod hordani!\n",
          images: [
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_enterior.jpg"), claimed: false },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_holdfeny_kicsi.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_lakota.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_techno_kicsi.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_tini_kicsi.jpg"), claimed: true },
            //szett
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_wedding_1.jpg"), claimed: true },
            { src: require("../assets/img/ugly-beauty/ugly_or_beuty_wedding_2.jpg"), claimed: true },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
