<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Veronika",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2012',
          tools: 'Video, photo',
          title: "VERONIKA'S WIPE",
          content: `I asked girls in my circle of friends who often wear make-up and then filmed them using a wipe to remove cosmetics from their faces. In the photos, you can see the paint marks on the wipes they used and their faces without make-up.`,
          images: [
            { src: require("../assets/img/veronika/veronika_kendoje_0.jpg"), claimed: false },
            { src: require("../assets/img/veronika/Veronika_kendoje_1.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/NT70ooDFjJ8', isVideo: true},
            { src: require("../assets/img/veronika/Veronika_kendoje_2.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/7J2a9gNyy5Q', isVideo: true},
            { src: require("../assets/img/veronika/Veronika_kendoje_3.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/IrZOwJE4isY', isVideo: true},
          ]
        },
      ],
      blocksHU: [
        {
          date: '2012',
          tools: 'Videó, fotó',
          title: "VERONIKA KENDŐJE",
          content: `Olyan lányokat kértem fel az ismeretségi körömből, akik gyakran sminkelik magukat, majd videóra vettem, ahogy egy törlőkendő segítségével eltávolítják arcukról a kozmetikumokat. A fotókon, a használt kendőkön megjelenő festék lenyomatok, valamint smink nélküli arcuk látható. `,
          images: [
            { src: require("../assets/img/veronika/veronika_kendoje_0.jpg"), claimed: false },
            { src: require("../assets/img/veronika/Veronika_kendoje_1.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/NT70ooDFjJ8', isVideo: true},
            { src: require("../assets/img/veronika/Veronika_kendoje_2.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/7J2a9gNyy5Q', isVideo: true},
            { src: require("../assets/img/veronika/Veronika_kendoje_3.jpg"), claimed: true },
            { video: 'https://www.youtube.com/embed/IrZOwJE4isY', isVideo: true},
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
