<template>
  <div class="gallery">
    <svg viewBox="0 0 19.77 21.58" class="svg__close" @click="closeGallery">
      <g>
	      <polyline class="st2" points="13.33,14.24 9.89,10.79 13.33,7.34 	" />
        <polyline class="st2" points="6.44,14.24 9.89,10.79 6.44,7.34 	" />
      </g>
    </svg>
    <svg viewBox="0 0 19.77 21.58"  class="arrow arrow-prev" @click="prev">
      <polyline class="st2" points="12.08,15.17 7.69,10.79 12.08,6.4 "/>
    </svg>

    <svg viewBox="0 0 19.77 21.58"  class="arrow arrow-next" @click="next">
      <polyline class="st2" points="12.08,15.17 7.69,10.79 12.08,6.4 "/>
    </svg>



    <transition name="fade" mode="out-in">
      <div :key="page">
        <div style="display: flex;
  align-items: center;
  justify-content: center;">
          <img :src="items[page].src" />
        </div>

        <!--        <div class="d-flex justify-content-center">-->
        <!--          <p>{{ items[page].name }}</p>-->
        <!--          <p class="text-year" v-if="!!items[page].year"> - {{items[page].year}}</p>-->
        <!--        </div>-->
      </div>

    </transition>

    <div class="d-flex justify-content-center">
      <svg viewBox="0 0 31.65 21.58" class="svg__dots">
        <g>
          <circle class="st0" cx="15.83" cy="10.79" r="1.53"/>
          <circle class="st0" cx="24.85" cy="10.79" r="1.53"/>
          <circle class="st0" cx="6.8" cy="10.79" r="1.53"/>
        </g>
      </svg>
      </div>



  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      page: 0,
      items: []
    };
  },
  methods: {
    closeGallery() {
      this.$store.commit("setGallery", []);
    },
    next() {
      this.page++;
      if (this.page === this.items.length) this.page = 0;
    },
    prev() {
      this.page--;
      if (this.page === -1) this.page = this.items.length - 1;
    }
  },
  mounted() {
    // let x = window.scrollX;
    // let y = window.scrollY;
    // window.onscroll = () => {
    //   window.scrollTo(x, y);
    // };
    this.page = this.$store.getters.galleryIndex;
    this.items = this.$store.getters.gallery;
  }
  // destroyed() {
  //   window.onscroll = () => {
  //   };
  // }
};
</script>

<style scoped>
.svg__dots{
  position: absolute;
  bottom: 10vh;
  width: 1.8vw;
}
.st0{
  fill: #BF99E4;
}
.st2{fill:none;stroke:#BF99E4;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
.text-year {
  margin-left: 0.7vw;
}

.arrow-prev {
  position: absolute;
  width: 1.8vw;
  left: 1.45vw;
  top: 22vw;
  cursor: pointer;


}


.arrow-next {
  position: absolute;
  width: 1.8vw;
  right: 1.45vw;
  top: 22vw;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.arrow {
  fill: #BF99E4;
  transition: 0.3s;
}

.arrow:hover path {
  fill: #A91974 !important;
  transition: 0.3s;
}

p {
  color: white;
  font-family: blackest_text_regular, serif;
  font-size: 2vw;
  margin-top: 3vw;
}

img {
  height: 70vh;
  /*margin-top: 2vw;*/
}

.svg__close {
  cursor: pointer;
  position: absolute;
  right: 1vw;
  top: 1vw;
  /*left: 51.22vw;*/
  /*top: 229.3vw;*/
  width: 30px;
  /*height: 4.32vw;*/
}

.svg__close .path-close {
  fill: #BF99E4;
  transition: 0.3s;
}

.svg__close:hover .path-close {
  fill: #A91974;
  transition: 0.3s;
}

.gallery {
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
}

@media only screen and (max-width: 768px) {
  .gallery{
    display: none;
  }
}
</style>