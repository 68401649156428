<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Bravo",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2017',
          tools: 'Posterzine',
          title: "BRAVO",
          content: `My photos are about the shameful parts of my own body. I'm a little ashamed to show them, but at the same time I want to embrace these details. To show everyone so that there are no more taboos on my body. To free myself from inhibitions. It is exhausting to work every day to conform to social norms. I want the viewers to identify with the images, to accept these body parts as they are, as opposed to societal expectations. The photos take the form of posters, evoking the posters of the former BRAVO magazine, which teenagers who were fans of various bands or groups would put up on their bedroom walls.`,
          images: [
            { src: require("../assets/img/bravo/BRAVO_1.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_2.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_3.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_4.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_5.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_6.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_7.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_8.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_9.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_enterior.jpg"), claimed: false },
          ]
        },
      ],
      blocksHU: [
        {
          date: '2017',
          tools: 'Poszterzine',
          title: "BRAVO",
          content: `Fotóim a saját testem szégyenletes részeivel foglalkoznak. Kicsit szégyellem megmutatni ugyanakkor mégis szeretném felvállalni ezeket a részleteket. Megmutatni mindenkinek, hogy ne legyen több tabu a testemen. Felszabadítani magam a gátlások alól. Fárasztó minden nap azért tenni, hogy a társadalmi normáknak megfeleljek. Szeretném, ha a nézők azonosulni tudnának a képekkel, hogy elfogadják ezeket a testrészeket olyannak amilyenek, szemben a társadalmi elvárásokkal. A fotók poszterzine formájába jelennek meg, megidézve az egykori BRAVO magazin posztereit, melyeket különböző zenekarokért, vagy együtteskért rajongó tinédzserek tettek ki a szobájuk falára. `,
          images: [
            { src: require("../assets/img/bravo/BRAVO_1.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_2.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_3.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_4.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_5.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_6.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_7.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_8.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_9.jpg"), claimed: true },
            { src: require("../assets/img/bravo/BRAVO_enterior.jpg"), claimed: false },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
