import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: 'en',
    gallery: [],
    galleryIndex: 0,
    menu: false,
  },
  getters:{
    language: state => {
      return state.language;
    },
    gallery: state => {
      return state.gallery;
    },
    galleryIndex: state => {
      return state.galleryIndex;
    },
    menu: state => {
      return state.menu;
    }
  },
  mutations: {
    setLanguage: function (state, value){
      state.language = value;
    },
    setGallery: function(state, value){
      state.gallery = value;
    },
    setGalleryIndex: function(state, value){
      state.galleryIndex = value;
    },
    setMenu: function(state, value){
      state.menu = value;
    }
  },
  actions: {},
  modules: {},
});
