<template>
  <div>
    <div style="display: compact; ">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN" :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU" :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Bio",
  mounted() {

  },
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods: {
    changeCategory(id) {
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: "contact",
      blocksEN: [
        {
          title: "SIMON ZSUZSI",
          tools: "Artist",
          content: `zsuzsanna.simon1@gmail.com\n+36205484089`
        },
        {
          title: "CERTIFICATES",
          content: `2009 - 2015 Hungarian University of Fine Arts, Intermedia, Budapest
2012 Jan Matejko Academy of Fine Arts, study abroad with ERASMUS scholarship spring semester, Krakow
2007 - 2009 NOVUS Art School, applied photographer, OKJ, Budapest
2003 - 2007 Bródy Imre High School, Fine Arts, Budapest
`
        },
        {
          title: "GROUP EXHIBITIONS",
          content: `2021 Let’s Eat!, Robert Capa Contemporary Photography Center, Budapest
2020 Waiting Room - Women Healers and Patients, Semmelweis Museum of Medical History
2019 PATRON Art Fair and Exhibition, Stúdió Gallery, Budapest
2018 I became an artist, I became a stranger, Studio Gallery, Budapest
2018 Erasing the future forever, Fkse annual exhibition, Barcsay Hall, Budapest
2018 BODIES, Studio Gallery, Budapest
2016 The artist is what?, Labor Gallery, Budapest
2016 ContURNuE EXPLOITATION // Get Out of Our Pants FESTIVAL, Aurora, Budapest
2015 Self-Readings, Studio Gallery, Pride Budapest
2014 FKSE annual exhibition, Gólya, Budapest
2014 Mind1átka - Online exhibition, Facebook
2014 UBIK space/time distortions, Casati Art Corner Gallery, Budapest
2013 Budapest, Hotel Casati, FFS summer collection
2013 Mass Insurance, Akvárium, Budapest
2012 Reflex Attact - Art Moments, Zsolnay Factory, Pécs
2012 Balatonszemes Vigadó - St. ÉgRe, XVII Szemes Festival, Balatonszemes
2012 In the Image of Mona Lisa II - Art Moments, MODEM, Debrecen
2012 Mona Lisa's Smile - Akvárium, Budapest
2012 City Scape - Künstlerhaus Bethanien, Berlin
2012 Manga Gallery, Krakow
2012 Hotel Forum - Hotel Forum 2, Krakow
2012 1 in 20 - MAGMA Contemporary Art Exhibition Space, Sfântu Gheorghe
2012 Hungry bitch growls - Exhibition of new members of FKSE, Studio Gallery
2011 Fictions of Reality - Exhibition of new members of FFS, Artbasis, http://fotopost.hu/textura/fikciok-vagy-valosag
2011 Urban Space Berlin Budapest, Demo Gallery, Budapest
2011 Dunaújváros Biennale, Institute of Contemporary Art /ICA-D/
2011 Balatonszemes Vigadó - St. Ég, XVII Szemes Festival, Balatonszemes
2011 Mai Manó Screening, Night of Museums
2010 - 2015 MKE (Hungarian University of Fine Arts) end of year exhibition, Budapest
2006 OKTV exhibition, Museum of Applied Arts, Budapest
`
        },
        {
          title: "SOLO EXHIBITION",
          content: `2022 Alexandru - ISBN books+gallery, Budapest
2018 Jewel Hair - Ugly or Beauty?, Lollipop Factory, Budapest
2013 See the Women, Kalicka Bistro, Budapest
2011 Tremble - with the support of MONA (Hungarian Women's Foundation), Fogasház, Budapest`
        },
        {
          title: "MEDIA APPEARANCES",
          content: `2022 More than body hair - Emese Mucsi, Glamour Vol. XIX No. 4
2022 Let’s eat! - Emese Mucsi - Glamour
2022 "We don't have that kind of spirit", Interview with Zsuzsi Simon, Gergely Nagy, A mű
https://amu.hvg.hu/2022/04/08/nekunk-nincs-olyanfajta-spiritunk-interju-simon-zsuzsival/
2022 Exchange of gaze - Judit Flóra Schuller, artportal
https://artportal.hu/magazin/tekintetcsere-simon-zsuzsi-alexandru-cimu-kiallitasarol/
2022 Mirror for the Male, György Cséka, ÚjMűvészet theoretical annex 22/1
2022 First hand - Alexandru.  artmagazin online
https://www.youtube.com/watch?v=rCUL7OmJ6ho
2021 It’s a relief to have nothing to hide. 6 Hungarian photographers on the power of the female gaze - Lili Rutai
https://www.calvertjournal.com/features/show/12707/hungarian-photographers-power-female-gaze
2021 Hairy Body - Fact plus show on public TV
2020 punkt.hu - "It is important for my projects that I am involved as a participant" - Interview with Zsuzsanna Simon - by Vera Fehér
https://punkt.hu/2020/12/04/fontos-a-projektjeim-soran-hogy-en-is-reszt-vegyek-bennuk-szereplokent-interju-simon-zsuzsannaval/
2016 Why does someone write on their belly that they don't want to be a government official? - Szily László
http://444.hu/2016/01/15/miert-ir-valaki-olyan-fol-a-hasara-hogy-kormanyvaltasig- no-coming
2014 Interview - Literary
Centrifuge
http://centrifuga.blog.hu/2013/05/29/tetten_erni_az_eroszakot_interju_simon_zsuzsannaval
2013 Balcony
2013 Photopost - Barta Edit
http://fotopost.hu/parbeszed/mindig-arra-torekszem-hogy-az- my-messages-to-my-members-on-legyen
2013 Female deformities
http://kekharisnya.blog.hu/2013/01/14/noi_torzok_a_kalickaban_simon_zsuzsanna_kiallitas
2011 Artportal, Urban Space Berlin Budapest
http://artportal.hu/aktualis/hirek/bihari_agnes_itt_van_a_varos_vagyunk_lakoi`
        },
        {
          title: "PUBLICATION",
          content: `2015 - #I am hurt too - 0m2.hu`
        },
        {
          title: "COVER",
          content: `2016 - organizer of Köz.kemp art camp, https://kozkemp.com
2017 President of the Studio Association of Young Artists /FKSE/
2015 - 2017 Member of the Board of the Studio Association of Young Artists /FKSE/
2015 -  Ferenc Mérei college membership
2013 - 2016 College of Art and Art Theory, NEMIgaz group - gender workshop
2011 - 2018 30 UNDER 30 membership - http://photoboite.com/3030/
2011 Member of the Studio Association of Young Artists /FKSE/
2011- 2017 Member of the Studio of Young Photographers /FFS/
`
        },
        {
          title: "OTHER",
          content: `2019 f++k Independent Art Department workshop
2016 Conversation with the Eleven Monument - Radio Forbidden
2016 Talk at the Eleven Monument - Floating "victim"
2010 www.reszletekben.blogspot.com
2004 - 2014 Tihany Artists' colony, creative camp.
`
        },
      ],
      blocksHU: [
        {
          title: "SIMON ZSUZSI",
          tools: "Artist",
          content: `zsuzsanna.simon1@gmail.com\n+36205484089`
        },
        {
          title: "TANULMÁNYOK",
          content: `2009 - 2015  Magyar Képzőművészeti Egyetem, intermédia szak, Budapest
2012 Jan Matejko Academy of Fine Arts, külföldi tanulmányok ERASMUS ösztöndíjjal tavaszi szemeszter, Krakkó
2007 - 2009 NOVUS Művészeti Iskola, alkalmazott fotográfus, OKJ, Budapest
2003 - 2007 Bródy Imre Gimnázium, képzőművészeti szak, Budapest`
        },
        {
          title: "CSOPORTOS KIÁLLÍTÁSOK",
          content: `2021 Let’s Eat!, Capa Központ, Budapest
2020 Várószoba - Női gyógyítók és páciensek, Semmelweis Orvostörténeti Múzeum
2019 PATRON Műtárgyvásár és kiállítás, Stúdió Galéria, Budapest
2018 Művésznek mentem, idegen lettem, Stúdió Galéria, Budapest
2018 A jövőt végképp eltörölni, Fkse éves kiállítás, Barcsay terem, Budapest
2018 BODIES, Stúdió Galéria, Budapest
2016 A képzőművész vajon mi?, Labor Galéria, Budapest
2016 KontÚrNő KIÁLLÍTÁS // Takarodjatok a Pinánkból FESZTIVÁL, Aurora, Budapest
2015 Ön-olvasatok, Stúdió Galéria, Pride Budapest
2014 FKSE éves kiállítás, Gólya, Budapest
2014 Mind1átka - Online kiállítás, Facebook
2014 UBIK tér/idő torzulások, Casati Art Corner Galéria, Budapest
2013 Budapest, Hotel Casati, FFS summer collection
2013 Tömegbiztosítás, Akvárium, Budapest
2012 Reflex Attact - Art Moments, Zsolnay Gyár, Pécs
2012 Balatonszemesi Vigadó - St. ÉgRe, XVII. Szemes Fesztivál keretében, Balatonszemes
2012 Mona Lisa képében II. - Art Moments, MODEM, Debrecen
2012 Mona Lisa mosolya - Akvárium, Budapest
2012 City Scape - Künstlerhaus Bethanien, Berlin
2012 Manga Galéria, Krakow
2012 Hotel Forum - Hotel Forum 2, Krakow
2012 1 híján 20 - MAGMA Kortárs Művészeti Kiállítótér, Sepsiszentgyörgy
2012 Éhes szajha morog - Az FKSE új tagjainak kiállítása, Stúdió galéria
2011 Valóság-fikciók - AZ FFS új tagjainak kiállítása, Artbázis, http://fotopost.hu/textura/fikciok-vagy-valosag
2011 Urban Space Berlin Budapest, Demo Galéria, Budapest
2011 Dunaújvárosi Biennálé, Kortárs Művészeti Intézet /ICA-D/
2011 Balatonszemesi Vigadó - St. Ég, XVII. Szemes Fesztivál keretében, Balatonszemes
2011 Mai Manó Vetítés, Múzeumok Éjszakája
2010 - 2015 MKE (Magyar Képzőművészeti Egyetem) év végi kiállítás, Budapest
2006 OKTV kiállítás, Iparművészeti Múzeum, Budapest
`
        },
        {
          title: "EGYÉNI KIÁLLÍTÁS",
          content: `2022 Alexandru - ISBN könyv+galéria, Budapest
2018 Ékszőr - Ugly or Beauty?,  Lollipop Factory, Budapest
2013 Lásd a Nőket, Kalicka Bistro, Budapest
2011 Reszketés (Tremble)- MONA (Magyarországi Női Alapítvány) támogatásával, Fogasház, Budapest
`
        },
        {
          title: "MÉDIA MEGJELENÉSEK",
          content: `2022 Let’s eat! - Mucsi Emese - Glamour
2022 Több, mint testszőr - Mucsi Emese, Glamour XIX. Évf. 4. szám
2022 “Nekünk nincs olyanfajta spiritünk”, interjú Simon Zsuzsival, Nagy Gergely, A mű
https://amu.hvg.hu/2022/04/08/nekunk-nincs-olyanfajta-spiritunk-interju-simon-zsuzsival/
2022 Tekintetcsere - Schuller Judit Flóra, artportal
https://artportal.hu/magazin/tekintetcsere-simon-zsuzsi-alexandru-cimu-kiallitasarol/
2022 Tükör a Férfinak, Cséka György, Új Művészet elméleti melléklete 22/1
2022 Első kézből - Alexandru.  artmagazin online
https://www.youtube.com/watch?v=rCUL7OmJ6ho
2021 It’s a relief to have nothing to hide. 6 Hungarian photographers on the power of the female gaze - Rutai Lili
https://www.calvertjournal.com/features/show/12707/hungarian-photographers-power-female-gaze
2021 Ugly or Beuty? - Tények plusz, TV2
2020 punkt.hu - “Fontos a projektjeim során, hogy én is részt vegyek benne szereplőként” - Interjú Simon Zsuzsannával - Készítette: Fehér Vera
https://punkt.hu/2020/12/04/fontos-a-projektjeim-soran-hogy-en-is-reszt-vegyek-bennuk-szereplokent-interju-simon-zsuzsannaval/
2016 Miért ír valaki olyat föl a hasára, hogy KORMÁNYVÁLTÁSIG NEM SZÜLÖK? - Szily László
http://444.hu/2016/01/15/miert-ir-valaki-olyan-fol-a-hasara-hogy-kormanyvaltasig- nem-szulok
2014 Interjú - Irodalmi
Centrifuga
http://centrifuga.blog.hu/2013/05/29/tetten_erni_az_eroszakot_interju_simon_zsuzsannaval
2013 Balkon
2013 Fotopost - Barta Edit
http://fotopost.hu/parbeszed/mindig-arra-torekszem-hogy-az- alkotasaimnak-uzenete-legyen
2013 Női torzok
http://kekharisnya.blog.hu/2013/01/14/noi_torzok_a_kalickaban_simon_zsuzsanna_kiallitas
2011 Artportal, Urban Space Berlin Budapest
http://artportal.hu/aktualis/hirek/bihari_agnes_itt_van_a_varos_vagyunk_lakoi
`
        },
        {
          title: "PUBLIKÁCIÓ",
          content: `2015 - #Nekem is fáj - 0m2.hu`
        },
        {
          title: "TAGSÁG",
          content: `2016 -  Köz.kemp alkotótábor szervezője, https://kozkemp.com
2017 Fiatal Képzőművészek Stúdiójának Egyesületének /FKSE/ elnöke
2015 - 2017 Fiatal Képzőművészek Stúdiójának Egyesületének /FKSE/ elnökségi tagja
2015 Mérei Ferenc Szakkollégium tagság
2013 - 2016 Művészet és Művészetelméleti szakkolégium, NEMIgaz csoport - gender műhely
2011 - 2018 30 UNDER 30 tagság - http://photoboite.com/3030/
2011 Fiatal Képzőművészek Stúdiójának Egyesületének /FKSE/ tagja
2011- 2017 Fiatal Fotóművészek Stúdiójának tagja /FFS/`
        },
        {
          title: "EGYÉB",
          content: `2019 f++k Független Képzőművészeti Tanszék workshop tartása
2016 beszélgetés az Eleven Emlékművel - Tilos rádió
2016 beszélgetés az Eleven Emlékműnél - Lebegő “áldozat”
2010 www.reszletekben.blogspot.com
2004 - 2014 Tihany Művész telep, alkotó tábor.`
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p {
  margin-right: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
