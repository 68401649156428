<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Parents",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2018',
          title: "MY BEAUTIFUL PARENTS - MY MOTHER WITH ROSES, MY FATHER WITH BUBBLES",
          isFlex: true,
          content: `Both my mother and father have a physical disability that they have had to live with for a long time. For a long time I didn't notice it, as I perceived it as natural and commonplace, until I started to deal with the body as a subject. My mother has a birthmark on her breast that she has carried since birth. My dad used to work in a pharmaceutical factory without any protective gear, where chemicals got into his body. It caused warts to form on the nerve endings and appear all over his body. In this project, too, I play an important role in acknowledging physical flaws, but I also try to create my own personal identity through the images. I photographed both of them in a spa pool, in the same pose, slightly turned inwards, thus putting myself in the place of the viewer.`,
          images: [
            { src: require("../assets/img/parents/gyonyoru_szuleim_anya_rozsaval.jpg"), claimed: true },
            { src: require("../assets/img/parents/gyonyoru_szuleim_apa_buborekokkal.jpg"), claimed: true },
          ]
        },
      ],
      blocksHU: [
        {
          date: '2018',
          title: "GYÖNYÖRŰ SZÜLEIM - ANYUKÁM RÓZSÁVAL, APUKÁM BUBORÉKOKKAL",
          isFlex: true,
          content: `Édesanyámnak és édesapámnak is van egy testi elváltozása, amivel régóta együtt kell élniük. Sokáig nem tűnt fel nekem, hiszen természetesnek és mindennaposnak érzékeltem, amíg nem kezdtem el a testtel mint témával foglalkozni. Anyukám mellén egy tűzfolt található, amit születése óta magán hordoz. Apukám régen egy gyógyszergyárban védőfelszerelés nélkül dolgozott, ahol vegyi anyag jutott a szervezetébe. Ennek a hatására az idegvégződésein szemölcsök alakultak ki, így az egész testén megjelentek. Ebben a projektben is fontos szerepet játszik a testi hibák felvállalása, ugyanakkor a saját személyes identitásomat is próbálom megteremteni a képek által. Mindkettőjüket gyógymedencében, ugyanabban a pózban, kicsit befelé fordulva fotóztam, ezáltal megidézve magamat a néző helyére.`,
          images: [
            { src: require("../assets/img/parents/gyonyoru_szuleim_anya_rozsaval.jpg"), claimed: true },
            { src: require("../assets/img/parents/gyonyoru_szuleim_apa_buborekokkal.jpg"), claimed: true },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
