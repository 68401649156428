<template>
  <div id="app">

    <div class="desktop">

      <div class="d-flex justify-content-center">



          <b-row style="height: 75vh; width: 1100px">
            <b-col cols="3">
              <p class="font__header font__family-500">ZSUZSI SIMON</p>
              <SideMenu />

            </b-col>
            <b-col cols="9" id="scrolltop">
              <div style="margin-left: 155px; margin-top: 75px;">
                <transition name="fade" mode="out-in">
                  <router-view />
                </transition>
              </div>

            </b-col>
          </b-row>


      </div>
      <div class="header__white" />
      <div class="bottom__white" />
    </div>

    <div class="mobile">
      <transition name="fade" mode="out-in">
        <div v-if="!$store.getters.menu" style="margin-top: 70px;">
          <router-view />
        </div>
        <div v-else>
          <SideMenu />
        </div>

      </transition>


      <div class="bottom__white">
        <p>©{{ new Date().getFullYear() }} Copyright ZSUZSI SIMON</p>
      </div>
      <div class="header__white">
        <p class="font__header font__family-500">ZSUZSI SIMON</p>

        <div class="hamburger">
          <div class="hamburger-icon"
               @click="toggleHamburger">
            <template v-for="(item, index) in 4">
              <span/>
            </template>
          </div>
        </div>
      </div>
    </div>






    <transition name="fade" mode="out-in">
      <div v-if="gallery.length > 0">
        <Gallery />
      </div>
    </transition>

  </div>
</template>

<script>
import Gallery from "./components/Gallery";
import SideMenu from "./components/SideMenu";

export default {
  name: "App",
  computed: {
    gallery: function() {
      return this.$store.getters.gallery;
    }
  },
  watch:{
    $route (to, from){
     this.toggleHamburger();
    }
  },
  data() {
    return {
      isOpened: false,
    }
  },
  components: {
    Gallery,
    SideMenu
  },
  methods:{
    toggleHamburger() {
      this.isOpened = !this.isOpened;
      this.$store.commit('setMenu', this.isOpened);
      let hamburger = document.getElementsByClassName("hamburger-icon");
      for (let i = 0; i < hamburger.length; i++) {
        this.isOpened ? hamburger[i].classList.toggle('open') :
          hamburger[i].classList.remove('open');
      }
    },
  }
};
</script>

<style>
.desktop .d-flex .main{
  width: 1100px;
  height: 100vh;
}
.bottom__white{
  background-color: white;
  width: 100vw;
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0
}

.header__white{
  background-color: white;
  width: 100vw;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}



.font__header {
  margin-left: 15px;
  position: fixed;
  font-size: 18pt;
  color: #404041;
  z-index: 10;
}

.font__header:before {
  position: absolute;
  left: -18px;
  top: -2px;
  content: "• ";
  color: #BF99E4;
}

@media only screen and (min-width: 768px) {
  .desktop{
    display: block;
  }

  .mobile{
    display: none;
  }

  #app {
    padding: 40px 70px;
  }
}

@media only screen and (max-width: 768px) {
  .desktop{
    display: none;
  }

  .mobile{
    display: block;
  }

  #app{
    /*padding-top: 0px;*/
    /*padding: 150px 30px 30px;*/
  }

  .header__white{
    height: 60px;
  }

  .header__white .font__header{
    font-size: 16pt;
    position: relative;
    margin-left: 25px;
    margin-top: 15px;
  }

  .bottom__white{
    height: 30px;
  }

  .bottom__white p{
    font-family: museo-500, serif;
    font-size: 6pt;
    color: #404041;
    margin-left: 30px;
    margin-top: 10px;
  }

  .hamburger {
    position: absolute;
    top: 21px;
    right: 15px;
    z-index: 12;
  }

  .hamburger-icon {
    width: 6.1vw;
    height: 1vw;
    /*position: relative;*/
    /*margin: 32px auto;*/
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

  }


  .hamburger-icon span {
    background: black;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .hamburger-icon span:nth-child(1) {
    top: 0px;
  }

  .hamburger-icon span:nth-child(2), .hamburger-icon span:nth-child(3) {
    top: 2vw;
  }

  .hamburger-icon span:nth-child(4) {
    top: 4vw;
  }

  .hamburger-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  .hamburger-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .hamburger-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .hamburger-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}


</style>
