<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Source",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2020',
          tools: 'Short story, photo',
          title: "ZSUZSANNA AT THE SOURCE",
          content: `Shower. Something that always calms me down and helps me to see clearly, whatever my situation or condition in life. For stress, nervousness, sadness, fatigue. Healing for my body and soul.
                    \nI undress. It's a bit cold. I'm cold, but I'm reassured that soon my naked body will be in contact with the warm water. I turn on the hot water and add a little cold, having learned over the years how to conjure the right temperature for my body from the old boiler. I'm a bit dizzy, I tell myself I've got a hangover, I guess I shouldn't have had 5 (or 6?) beers the day before, and there was some spirit at dawn. Yuck. Thanks guys for getting me drunk.
                    \nI get in the shower, let the water run down my legs first, then slowly work my way up, belly, breasts, back, finally let it run down my body. I love this feeling, if I could I would stand under it all day. The only thing that would make me happier than this would be taking a bath, because at least I could read my (erotic romance) novel, talk on the phone (with my mum), surf the internet (because I need new shoes), listen to music (on Spoty), chat (with my friends), or just lie in it and relax while the warm steam rises in the bathroom. Unfortunately, for now, I'll have to settle for a shower.
                    \nI squeeze some shower gel into my hands. Luckily, this one lathers really well, so I add a little water and rub it on. I like to watch as I put the foam on my chest, maybe because it's the only part of my body I'm relatively happy with, of course it's already suffered a bit from gravity. As I progress downwards I can feel myself getting a bit fat, my belly has got bigger, I can barely see my protruding curly, thick, dark pubic hair, my butt and thighs are slowly coalescing, even though I pay for live videos of my trainer posting online to a Facebook group. It makes me think of how much I miss my old life, when I could go to his gym three times a week and eat a Caesar salad at Móricz after a workout. It worked well. Not to mention, it was a lot easier to stick to my diet back then. It's funny, because I thought a lot of times I couldn't eat healthy and pay attention to my diet because I didn't have the time. Now that I'm here, I have time like the sea, but it's still hard. I even have trouble coming up with a classic meal to cook, let alone prioritising my diet . I have to get my act together because it's not going to work. I’ll start training tomorrow.
                    \nThe way I usually go about my washing is to wash my upper body first, then my genitals and buttocks, legs, then I'll squeeze on another load of shower gel and then my back, as much as I can reach. I never wash my soles and toes because I have a theory that the foamy shower gel will run down there anyway, plus I'm standing in water in the shower tray by default, so that feels sufficient.
                    \nI'm on my genital area, I think it's the most foamy part of my body, it must be the massive hair that triggers the shower gel reaction so much. Legs, I'll linger here for a bit, I haven't shaved them in a long time, and neither my bikini line nor my armpits. Of all of them, wearing it on the legs is the hardest, no doubt. I wonder if I'll have the courage to keep it for the summer. I'm used to armpits, I haven't shaved them in 2 years, but pubic hair and legs are different. I once had a conversation with my friend D, discussing the situation of pubic hair sticking out of a swimsuit. The question was whether people are bothered by the sight because it's embarrassing and they're not used to it, or whether they find it too intimate. It's an interesting question, but in any case the reaction is the same, shock. A thought occurs to me that perhaps the reason I like nudist beaches so much is that no one says where you have hair or not.
                    \nI feel sadness all of a sudden. I wonder what will happen this summer? Will the lockdown continue, or will I be able to celebrate my birthday with my beloved friends at our summer house? One thing's for sure, if I do, I swear I'll leave my leg hair on!
                    \nI'm actually angry too, angry that they took a spring and maybe a summer out of my life. How will there be a festival? A vacation with my parents? My dear parents, whom I haven't seen in a very long time, whom I used to visit every week. I'm feeling sad again, but at least I'm in the shower and I immediately feel calm. I try to brush away my thoughts and all of a sudden I find myself completely clean, all that's left is to wash my face. I've been using a gel for at least 15 years, I'm happy with it, it washes out my blackheads nicely, but I don't feel like using it now, I'll use it another time. I turn off the water, pull the curtains. Suddenly I feel a chill, goosebumps, my nipples get hard ( this is when I like them the most) I hang up the towel and soak the water all over my body.`,
          images: [
            { src: require("../assets/img/simon_zsuzsi_after_bathing_small.jpg"), claimed: false },
          ]
        }
      ],
      blocksHU: [
        {
          date: '2020',
          tools: 'Novella, fotó',
          title: "ZSUZSANNA A FORRÁSNÁL",
          content: `Stúdió Naplók címmel létrejött egy weboldal, ahol a Fiatal Képzőművészek Stúdiója Egyesületének tagjai jelenhettek meg az online térben, a karantén ideje alatt. Ennek keretén belül készült el a történetem és a hozzá tartozó fotóm.
\nZuhanyzás. Ami mindig megnyugtat és segít tisztán látni, bármilyen élethelyzetben és állapotban legyek. Stresszre, idegességre, szomorúságra, fáradtságra. Gyógyír a testemnek és lelkemnek.
\nLevetkőzöm. Kicsit hideg van. Fázom, de nyugodtsággal tölt el, hogy hamarosan a mezítelen testem érintkezhet a meleg vízzel. Megnyitom a meleg vizet és adok hozzá egy kicsi hideget, az évek során már kitapasztaltam, hogyan is kell a testemnek megfelelő hőmérsékletet varázsolni a régi bojlerből. Kicsit megszédülök, megállapítom magamban, hogy másnapos vagyok, azt hiszem nem kellett volna előző nap 5 db (vagy 6 db?) sört innom, ja meg volt valami pálinka is hajnalban. Fúj. Köszi srácok, hogy leitattatok.
\nBeállok a zuhany alá, először a lábamra engedem a vizet, majd szépen haladok egyre följebb, has, mell, hát, végül hagyom végigfolyni a testemen. Imádom ezt az érzést, ha tehetném egész nap alatta állnék. Ennél már csak a kádazás töltene el nagyobb boldogsággal, mert közben legalább tudnám olvasni (az erotikus romantikus) regényem, tudnék telefonálni (anyukámmal), internetezni (mert kéne egy új cipő), zenét hallgatni (a spotyn), chatelni (a barátaimmal), vagy csak feküdni benne és relaxálni, míg a meleg pára felszáll a fürdőszobában. Egyelőre sajnos be kell érnem a zuhanyzással.
\nNyomok egy kis tusfürdőt a kezembe. Szerencsére ez a fajta nagyon jól habzik, engedek hozzá egy kis vizet és magamra kenem. Szeretem nézni ahogy a mellemre teszem a habos krémet, talán azért, mert az egyetlen testrészem, amivel viszonylag elégedett vagyok, persze már a gravitációt kicsit megsínylette. Ahogy haladok lejjebb érzem, hogy kicsit meghíztam, a hasam nagyobb lett, alig látom a kiálló göndör, sűrű, sötét fanszőrömet, a fenekem és a combom lassan összeér, pedig fizetem az edzőm által készített live videókat, amit online egy facebook csoportba posztol. Eszembe jut, hogy mennyire hiányzik a régi életem, amikor heti háromszor el tudtam járni hozzá a fitness terembe és edzés után enni egy cézár salátát a Móriczon. Jól működött. Arról nem beszélve, hogy akkor a diétát is sokkal könnyebb volt tartanom. Furcsa, mert azt hittem, sokszor azért nem tudok egészségesen étkezni és odafigyelni a diétára, mert nincs rá időm. Most meg, hogy itt vagyok, időm, mint a tenger, mégis nehéz. Még az is problémát okoz, hogy egy klasszikus ételt kitaláljak, amit főzhetünk, nem hogy előnyben részesítem a diétámat . Össze kell kapnom magam, mert ez így nem lesz jó. Holnaptól edzeni fogok.
\nÚgy szoktam haladni a mosakodással, hogy először elől megmosom a felső testem, aztán  nemiszerv és fenék, lábszárak, majd újra nyomok egy adag tusfürdőt és jön a hátam, már amit elérek belőle. A talpamat és a lábujjaimat sose szoktam megmosni, mert az az elméletem, hogy oda úgy is lefolyik a habos tusfürdős víz, plusz alapból vízben tocsogok a zuhanytálcában, így ezt elégnek érzem.
\nA nemiszervemnél tartok, szerintem ez a legjobban habzó testrészem, biztos a masszív szőrtől indul be ennyire a tusfürdő reakciója. Lábszár, itt kicsit elidőzöm, jó rég óta nem borotváltam le, ahogyan a bikinivonalat és a hónaljamat sem. Mind közül a lábon hordani a legnehezebb, semmi kétség. Belegondolok, hogy vajon lesz-e bátorságom meghagyni a nyáron. A hónaljat már megszoktam, 2 éve nem borotválom, de a fanszőr és a láb az más. Egyszer volt egy beszélgetésem D. barátnőmmel, a fürdőruhából kilógó fanszőr helyzetét vitattuk. Az volt a kérdés, hogy az embereket azért zavarja a látvány, mert ciki és nem szoktak hozzá, vagy pedig túl intimnek tartják. Érdekes kérdés, mindenesetre a reakció ugyanaz, megrökönyödést vált ki. Beugrik egy gondolat, hogy talán azért is szeretem annyira az FKK strandokat, mert ott senki nem szól bele abba, hogy hol van szőröd, vagy hol nincs.
\nElfog a szomorúság. Vajon mi lesz a nyáron? Fog még tartani a bezártság, vagy a szeretett barátaimmal megünnepelhetem a szülinapomat a nyaralónkban? Egy biztos, ha igen, én esküszöm fent hagyom a lábszőrömet!
\nIgazából dühös is vagyok, dühös azért, mert elvettek az életemből egy tavaszt és lehet, hogy egy nyarat is. Hogy lesz így fesztivál? Nyaralás a szüleimmel? A drága szüleim, akiket már nagyon régóta nem láttam, akiket azelőtt hetente látogattam. Újra szomorúság fog el, de legalább a zuhany alatt állok és egyből megnyugszom. Megpróbálom elhessegetni a gondolataimat és azon kapom magam, hogy teljesen tiszta lettem, már csak az arcmosás van hátra. Legalább 15 éve használok egy gélt, elégedett vagyok vele, szépen kimossa a miteszereimet, de most még sincs kedvem használni, majd inkább máskor. Elzárom a vizet, kihúzom a függönyt. Hirtelen megcsap a hideg, libabőrös leszek, a mellbimbóim megkeményednek (ilyenkor szeretem csak igazán őket). Leakasztom a törölközőt és szépen felitatom a vizet az egész testemen.
`,
          images: [
            { src: require("../assets/img/simon_zsuzsi_after_bathing_small.jpg"), claimed: false },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
