<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Trembling",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2011',
          title: "TREMBLING",
          content: `During the project, I went to photograph locations where public rape had occurred in the past. I selected the cases from police reports and the media. From the research I learned that the greatest danger to women is not on the streets. 70% of all rapes are committed by acquaintances, family friends or relatives. The majority of rapes therefore take place at home, or within the 'safe' walls of the homes of friends and acquaintances.`,
          images: [
            //városmajor
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_1.jpg"), claimed: false,
            text: 'On 16 October 2004, a schoolgirl was walking home late at night in Városmajor Street. The 17-year-old teenager had barely said goodbye to her friends before she was caught a few hundred metres away. She was raped, brutally assaulted and robbed. The half-dead girl was found by poster hangers on the embankment of the rack railway. The perpetrators were a 23-year-old and a 22-year-old man.'},
            //buszmegálló 173 egymás mellé ez a két kép
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_2_1.jpg"), claimed: false },
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_2_2.jpg"), claimed: false,
            text: 'On 29 July 2007, after 10 p.m., at the time of the offence, a 15-and-a-half-year-old girl, was waiting at the 173 bus stop at Fő tér, in the XV district of Budapest, when a man grabbed her neck and shoulders from behind, dragged her into the bushes on Fő tér, took her phone, switched it off and threw it away. He pushed her to the ground between the bushes, gagged her, pulled down her trousers and underwear with his other hand, pushed down his own trousers and then had intercourse with her from the front.  She saw his face and recognised her old boyfriend, who had been harassing her after the break-up because he wanted to make up.'},
            //normafa
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_3.jpg"), claimed: false,
            text: 'On 2 July 2002, the stripped toilet attendant was found bleeding in Normafa in the XII district. Her assailant had beaten her so badly that the severe injury to her facial skull caused a haemorrhage and a life-threatening condition. The elderly woman was also sexually assaulted by the perpetrator, who placed various objects in the victim\'s orifices. The creep of Normafa is in custody. Although he denies everything, the police suspect him of being the perpetrator of several violent crimes committed in the spring and summer.'},
            //3.kerület
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_5.jpg"), claimed: false,
            text: 'On 30 October 2005, at 5 a.m., a young girl was returning home from a Saturday party in Budapest\'s 3rd District. She got off the tram at the terminus and was walking along her usual route to her block of flats when she noticed two young men following her. She quickened her steps, and then the boys picked up the pace as well. The girl turned into the street and suddenly out of nowhere the boys appeared beside her, immediately gagged her and pushed her to the ground. As soon as they sensed her resistance, they started punching and kicking her, and when she was unable to defend herself, they raped her at least four times.'},
          ]
        }
      ],
      blocksHU: [
        {
          date: '2011',
          title: "RESZKETÉS",
          content: `A projekt során olyan helyszínekre mentem el fotózni, ahol korábban köztéri nemi erőszak történt. Az eseteket, rendőrségi jegyzőkönyvekből és a médiából válogattam össze. A kutatásból megtudtam, hogy a legnagyobb veszély nem az utcán leselkedik a nőkre. Az összes nemi erőszak 70%-át ismerős, családi barát, rokon követi el. A nemi erőszakok nagy része tehát, az otthon, vagy a barátok és ismerősök otthonának “biztonságos”falai között történik.`,
          images: [
            //városmajor
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_1.jpg"), claimed: false,
            text: '2004. október 16.-án a Városmajor utcában késő éjjel hazafelé tartott egy diáklány. A 17 éves  tinédzser alig köszönt el a barátaitól, pár száz méterrel odébb már el is kapták. Megerőszakolták, brutálisan bántalmazták és kizsebelték. A félholt lányra plakátragasztók találtak rá a fogaskerekű vasút töltésénél. Az elkövetők egy 23 és egy 22 esztendős férfi voltak.'},
            //buszmegálló 173 egymás mellé ez a két kép
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_2_1.jpg"), claimed: false },
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_2_2.jpg"), claimed: false,
            text: '2007. július 29-én, este 10 óra után a cselekmény elkövetésének idején 15 és fél éves lány Budapesten, a XV. kerületi Fő téren, a 173-as busz megállójában várta az autóbuszt, amikor egy férfi hátulról megragadta a nyakát és vállát, majd a Fő téren lévő bokros területre hurcolta, telefonját elvette, kikapcsolta és eldobta A bokrok között a földre lökte, a száját befogta, másik kezével nadrágját és alsónemüjét lehúzta, végül saját nadrágját is letolta, majd szemből közösült vele. A lány eközben látta a férfi arcát, és felismerte régi barátját, aki szakítás után folyamatosan zaklatta, mert ki akart békülni vele.'},
            //normafa
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_3.jpg"), claimed: false,
            text: '2002. július 2-án a XII. kerületben, a Normafánál  véresen találtak rá a lemeztelenített vécés nénire. Támadója olyan súlyosan ütlegelte, hogy arckoponyájának súlyos sérülése vérbelehelést és ennek következtében életveszélyes állapotot idézett elő. Az idős asszonyt szexuálisan is bántalmazta a tettes, különböző tárgyakat helyezett el az áldozat testnyílásaiban. A Normafa szatírja, előzetes letartóztatásban van. Bár mindent tagad, a rendőrség feltételezése szerint ő a tettese több, tavasszal és nyáron elkövetett erőszakos bűncselekménynek is.'},
            //3.kerület
            { src: require("../assets/img/shiver/simon_zsuzsanna_reszketes_5.jpg"), claimed: false,
            text: '2005. október 30-án hajnali 5 órakor Budapest III. Kerületében egy szombati buli után tért volna haza egy fiatal lány. Leszállt a végállomáson a villamosról és a megszokott útvonalán indult el a panelházuk felé, amikor észrevette, hogy két fiatal férfi követi. Megszaporázta lépteit, ekkor fiúk is sietősebbre fogták. A lány befordult az utcába és hirtelen a semmiből mellette termettek a fiúk, a száját azonnal befogták, majd a földre teperték. Amint megérezték az ellenállást a lányon, ütni, rúgni kezdték, majd amikor már védekezésre képtelen volt, legalább négy alkalommal követtek el rajta nem erőszakot.'},
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
