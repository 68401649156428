<template>
  <div>
    <div style="display: compact;">


      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'en'" v-for="(item, index) in blocksEN"  :key="`section__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>
      <transition-group name="fade" tag="div" mode="out-in">
        <div v-if="language === 'hu'" v-for="(item, index) in blocksHU"  :key="`section-hu__${index}`">
          <content-block
            :id="`section__${index}`"
            :title="item.title"
            :tools="item.tools"
            :date="item.date"
            :is-flex="item.isFlex"
            :content="item.content"
            :images="item.images" />
        </div>
      </transition-group>

    </div>


  </div>
</template>

<script>
import ContentBlock from "../components/ContentBlock";

export default {
  name: "Shy",
  computed: {
    language: function() {
      return this.$store.getters.language;
    }
  },
  components: {
    ContentBlock
  },
  methods:{
    changeCategory(id){
      this.bioCategory = id;
    }
  },
  data: function() {
    return {
      bioCategory: 'contact',
      blocksEN: [
        {
          date: '2012',
          title: "SHY GIRLS",
          content: `I asked girls from my circle of friends to stand naked in front of my camera. The girls had to bring an object to cover their faces. The different objects refer to the girls' personalities and their thoughts about their bodies. My aim was to create a situation in which they had to assume their naked bodies, but at the same time their identities were still partially hidden thanks to the objects they chose.`,
          images: [
            { src: require("../assets/img/shy/szegyenlos_lanyok_1.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_2.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_3.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_4.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_5.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_6.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_7.jpg"), claimed: true },
          ]
        },
      ],
      blocksHU: [
        {
          date: '2012',
          title: "SZÉGYENLŐS LÁNYOK",
          content: `Lányokat kértem meg az ismeretségi körömből, hogy álljanak meztelenül a kamerám elé. A lányoknak hozniuk kellett egy tárgyat, amivel eltakarhatták az arcukat. A különböző tárgyak a lányok személyiségére, a testükkel kapcsolatos gondolataikra utalnak. Az volt a célom, hogy olyan helyzetet teremtsek, amiben fel kell vállalniuk meztelen testüket, ugyanakkor kiválasztott tárgyaiknak köszönhetően mégis részben rejtve maradt identitásuk.`,
          images: [
            { src: require("../assets/img/shy/szegyenlos_lanyok_1.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_2.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_3.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_4.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_5.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_6.jpg"), claimed: true },
            { src: require("../assets/img/shy/szegyenlos_lanyok_7.jpg"), claimed: true },
          ]
        },
      ]
    };
  }
};
</script>

<style scoped>
.selector p{
  margin-right: 20px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
